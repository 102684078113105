export const environment = {
    envName: 'pre',
    production: false,
    serviceUrl: 'https://pre-apim.integration.bacardiapps.com/v1/Sonar',
    clientId: 'client_api_web',
    clientSecret: 'somesecret',
    pageNumElements: [8],
    apiKey: 'LFsvtyzBc1OlGwaSOACGSDt9pTtNoiO4',
    gtm: null,
    gtmNoscript: null,
    oauth2ClientId: 'LFsvtyzBc1OlGwaSOACGSDt9pTtNoiO4',
    oauth2ClientSecret: 'tCsz7YGRMcGJgy3V',
    externalUrls: {
        onePager: 'https://app.powerbi.com/groups/ffb6e826-f0bb-4831-aabc-e6faa5b0628d/reports/500999eb-23a8-4f3c-b02d-12bb68a5b110/ReportSection1061f72b8b212771a1d3',
        activityTracker: 'https://app.powerbi.com/groups/ffb6e826-f0bb-4831-aabc-e6faa5b0628d/reports/495be258-5abe-4e4e-93c7-37e3fedde7c5/ReportSection199c265e8018e3a52ea4',
        roiSimulator: 'https://app.powerbi.com/groups/cd008e4c-725c-453f-947c-057e2ed5354a/reports/e789b24f-7e01-4622-b6ca-d0d0f32302db/ReportSection47a6e4b5d5a07e25c9ff?experience=power-bi',
    },
};
